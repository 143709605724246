import React, { Fragment, Component } from "react";
import { Button, Row } from "antd";
import { initInfoData, initSubscriberData } from "../../common/Constants";
import { validateEmail } from "../../common/DumpFunctions";
import {
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithGmail,
} from "../../firebase";
import { Button as ReactStrapButton } from "reactstrap";

const initState = {
  email: "",
};

class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initState };
    signInWithEmailLink(this.addNewSubscriber);
  }

  addNewSubscriber = (user) => {
    if (this.props.addNewSubscriber) {
      let subscriber = {};
      Object.keys(initSubscriberData).forEach((key) => {
        subscriber[key] = user[key];
      });
      subscriber._id = user.uid;
      this.props.addNewSubscriber(subscriber);
    }
  };

  //  Clear all state/fields values
  clearState = () => {
    this.setState({ ...initState });
  };
  // Notification
  openNotification = () => {
    this.clearState();
    sendSignInLinkToEmail(this.state.email, window.location.href);
  };

  //  Form validation
  validateForm = () => {
    const { email } = this.state;
    return !validateEmail(email);
  };

  //  Get the typed values
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { email } = this.state;
    const { information = { ...initInfoData }, subscriber } = this.props;
    return (
      <Fragment>
        <div className="bg-cover" id="subscription-box">
          <h3 className="text-center text-white">News and Updates</h3>
          <p className="text-center">
            {!subscriber
              ? "Subscribe to our newsletter and receive the latest news from"
              : "You are already subscribed to the " + information.Name}
          </p>
          {!subscriber && (
            <>
              <div className="form-row">
                <div className="col-md-4 m-auto d-flex justify-content-end">
                  <div className="input-group input-group-lg z-depth-1 w-100">
                    <div className="input-group-prepend">
                      <span className="input-group-text rgba-white-light border-0">
                        <i className="fa fa-envelope white-text"></i>
                      </span>
                    </div>
                    <input
                      required
                      type="email"
                      name="email"
                      className="form-control form-control-lg rgba-white-light white-text border-0 z-depth-0"
                      placeholder="Email Address"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </div>
                  <ReactStrapButton
                    size="lg"
                    color="info"
                    disabled={this.validateForm()}
                    onClick={this.openNotification}
                  >
                    Subscirbe
                  </ReactStrapButton>
                </div>
              </div>
              <Row className="d-flex justify-content-between p-5">
                <Button
                  size="large"
                  icon="google"
                  onClick={() => signInWithGmail(this.addNewSubscriber)}
                >
                  With Gmail
                </Button>
              </Row>
            </>
          )}
        </div>
      </Fragment>
    );
  }
}

export default Subscribe;
