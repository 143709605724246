import { Button } from "antd";
import React, { Component } from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Col, Row, Card } from "reactstrap";
import CarCard from "../common/CarCard";
import { home_slider } from "../common/cssObjects";
import Services from "../components/Services/Services";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: 3, loading: false };
  }
  loadMore = () => {
    this.setState({ loading: true }, () => {
      setTimeout(() => {
        this.setState((prev) => {
          return { visible: prev.visible + 3, loading: false };
        });
      }, 500);
    });
  };

  render() {
    let { allData = [], information = {} } = this.props.data;
    let carsToShow = allData.filter((car) => car.show);
    let carsToShowHome = carsToShow
      .filter(
        ({ HomePage, Photos, DealerPrice }) =>
          HomePage && Photos.length > 0 && DealerPrice,
      )
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    let SliderImages = information.SliderImages.map((src, index) => ({
      src,
      original: src,
      style: home_slider,
      thumbnail: src,
      alt: information?.Captions[index]?.toUpperCase(),
    }));

    let renderSlider = (
      <ImageGallery
        slideInterval={2500}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={false}
        items={SliderImages}
        autoPlay
      />
    );
    if (information.SelectedSlider === "Carousel") {
      renderSlider = (
        <Carousel
          images={SliderImages}
          isAutoPlaying
          autoPlayInterval={2500}
          hasRightButton={false}
          hasLeftButton={false}
          hasMediaButton={false}
          hasIndexBoard={false}
          hasThumbnails={false}
          hasSizeButton={false}
          hasDotButtons="bottomCenter"
          hasCaptions="bottomLeft"
        />
      );
    }

    return (
      <React.Fragment>
        <Row>
          <Col>{renderSlider}</Col>
        </Row>
        <Services />
        <Card>
          <h2 className="text-center text-gray myy">Featured Vehicles</h2>
          <Row className="m-md-4">
            {carsToShowHome.slice(0, this.state.visible).map((car) => (
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={4}
                key={`car-col-${car._id}`}
                className="mb-2"
              >
                <CarCard key={car._id} {...this.props} car={car} information={information} />
              </Col>
            ))}
          </Row>
          {this.state.visible < carsToShowHome.length && (
            <div className="row mx-auto">
              <Button
                type="default"
                loading={this.state.loading}
                className="mx-auto mb-3"
                onClick={this.loadMore}
              >
                Load more
              </Button>
            </div>
          )}
        </Card>
      </React.Fragment>
    );
  }
}
