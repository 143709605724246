const baseURL = (() => {
  if (process.env.NODE_ENV === "development") {
    return (
      process.env.REACT_APP_BASE_URL_LOCAL ||
      process.env.REACT_APP_BASE_URL_PRODUCTION
    );
  } else if (process.env.NODE_ENV === "production") {
    return process.env.REACT_APP_BASE_URL_PRODUCTION;
  }
})();
export const serverURL = {
  car: `${baseURL}/car`,
  info: `${baseURL}/info`,
  subscriber: `${baseURL}/subscriber`,
};

export const initCarData = {
  Name: "",
  Model: "",
  Year: "",
  Mileage: "",
  Photos: [],
  Transmission: "",
  DealerPrice: "",
  VIN: "",
  Kilometres: "",
  Condition: "",
  BodyStyle: "",
  Engine: "",
  StockNo: "",
  ExteriorColour: "",
  InteriorColour: "",
  DriveTrain: "",
  Doors: "",
  FuelType: "",
  Overview: "",
  Mechanical: "",
  Exterior: "",
  Entertainment: "",
  Interior: "",
  Safety: "",
  Sold: false,
  HomePage: false,
  show: true,
};

export const TagColors = [
  "magenta",
  "red",
  "volcano",
  "orange",
  "gold",
  "lime",
  "green",
  "cyan",
  "geekblue",
  "purple",
];

export const getRandomTagColor = () => {
  return TagColors[Math.floor(Math.random() * TagColors.length)];
};

export const soldImageURL =
  "https://firebasestorage.googleapis.com/v0/b/autocitygroup-1c9af.appspot.com/o/Sold.png?alt=media&token=5b460076-2da0-45d5-9b01-c28bd8597e59";
export const imageNotAvailableURL =
  "https://d2638j3z8ek976.cloudfront.net/67b163269d4aabe47b71d14c2cc43f2ea797ed3e/1551107236/images/no-images/no-image-stock.png";

export const CarCardInfoValues = [
  { id: "VIN", icon: "car" },
  { id: "Kilometres", icon: "tachometer" },
  { id: "Condition", icon: "paint-brush" },
  { id: "BodyStyle", icon: "car" },
  { id: "Engine", icon: "cog" },
  { id: "Transmission", icon: "key" },
];

export const initInfoData = {
  Name: "Auto City Group",
  Phones: [],
  Fax: [],
  Email: "autocitygroup.ca@gmail.com",
  Slug: "Full Service Automatic Car Repairing Center",
  Location: { lat: 0, lng: 0 },
  Logo: "https://firebasestorage.googleapis.com/v0/b/autocitygroup-1c9af.appspot.com/o/logo.png?alt=media&token=18c0c8ca-cdd4-4e5e-8ce5-71572ced74e4",
  OpenTime: "08:00 AM",
  CloseTime: "05:00 PM",
  SliderImages: [],
  SelectedSlider: "Carousel",
  Captions: [],
};

export const initSubscriberData = {
  displayName: "",
  phoneNumber: "",
  email: "",
  photoURL: "",
  address: "",
};

export const TabsAdmin = [
  { text: "New Inventory", number: "1" },
  { text: "Display Inventory", number: "2" },
  { text: "Site Info", number: "3" },
  { text: "Subscribers", number: "4" },
  { text: "Add Subscriber", number: "5" },
];

export const sliders = ["Carousel", "ReactImageGallery"];
