import React, { Component } from 'react';
import { initInfoData } from '../../common/Constants';
import { change24to12 } from '../../common/DumpFunctions';

class Map extends Component {
  render() {
    const { information = { ...initInfoData } } = this.props;
    const time = [
      change24to12(information.OpenTime),
      change24to12(information.CloseTime),
    ];

    return (
      <div id='map-box'>
        <section className='contact-section dark-grey-text'>
          <div className='card'>
            <div className='row'>
              <div className='col-sm-8'>
                <div className='card-body form p-1 m-sm-5'>
                  {/*Google map*/}
                  <div
                    id='map-container-google-1'
                    className='z-depth-1 map-container'
                  >
                    <iframe
                      title='map'
                      src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d265.04262305439994!2d-106.69145445574085!3d52.12083038596354!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdd8c8ff111aa7121!2sAuto%20City%20Group-Auto%20Sale%20And%20Repair!5e0!3m2!1sen!2sus!4v1619738472193!5m2!1sen!2sus'
                      style={{ border: 0 }}
                      allowFullScreen=''
                      loading='lazy'
                    ></iframe>
                  </div>
                  {/*Google Maps*/}
                </div>
              </div>
              <div className='col-sm-4 mdb-color darken-2'>
                <div className='card-body contact text-center h-100'>
                  <h3 className='font-weight-bold my-4 pb-2'>
                    Contact information
                  </h3>
                  <ul className='text-lg-left list-unstyled ml-4'>
                    <li>
                      <p>
                        <i className='fas fa-map-marker-alt pr-2' />
                        618 Ave. O S, Saskatoon, SK S7M 2S3, Canada
                      </p>
                    </li>
                    {information.Phones.map((phone) => (
                      <li key={`phones-${phone}`}>
                        <p>
                          <i className='fas fa-phone pr-2' />
                          <a href={`tel:${phone}`}>{phone}</a>
                        </p>
                      </li>
                    ))}
                    {information.Fax.map((phone) => (
                      <li key={`phones-${phone}`}>
                        <p>
                          <i className='fas fa-fax pr-2' />
                          <a href={`tel:${phone}`}>{phone}</a>
                        </p>
                      </li>
                    ))}
                    <li>
                      <p>
                        <i className='fas fa-clock pr-2' />
                        {time.join(' - ')}
                      </p>
                    </li>
                    <li>
                      <p>
                        <i className='fas fa-envelope pr-2' />
                        {information.Email}
                      </p>
                    </li>
                  </ul>
                  <hr className='hr-light my-4' />
                  <ul className='list-inline text-center list-unstyled user-select-none'>
                    <li className='list-inline-item'>
                      <a
                        className='p-2 fa-lg tw-ic'
                        href='https://web.facebook.com/autocitygroup.ca'
                        target='_blank'
                      >
                        <i className='fab fa-facebook' />
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a className='p-2 fa-lg li-ic'>
                        <i className='fab fa-google'> </i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a
                        className='p-2 fa-lg ins-ic'
                        target='_blank'
                        href='http://api.whatsapp.com/send/?phone=%2B113067155555&text=Hello,%20I%20just%20visit%20Autocitygroup%20website'
                      >
                        <i className='fab fa-whatsapp'> </i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Map;
