import { Avatar, Button, Icon, notification, Spin, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row, TabPane } from "reactstrap";
import { initSubscriberData } from "../../common/Constants";
import InputField from "../../common/InputField";
import { uploadImageOnFirestore } from "../../firebase";

const AddNewSubscriber = (props) => {
  const getSubscriberData = (data) => {
    if (!data) data = {};
    let {
      displayName = "",
      phoneNumber = "",
      email = "",
      photoURL = "",
      address = "",
    } = data;
    data = {
      displayName,
      phoneNumber,
      email,
      photoURL,
      address,
      _id: data._id || Date.now().toString(),
    };
    Object.keys(data).forEach((key) => {
      if (!data[key]) data[key] = "";
    });
    return data;
  };

  const [profile, setProfile] = useState(getSubscriberData(props.subscriber));

  const [loading, setLoading] = useState(false);

  const updateProfile = () => {
    setLoading(true);
    if (props.addSubscriber) {
      props.addSubscriber(profile, () => setLoading(false));
    } else if (props.updateSubscriber) {
      props.updateSubscriber(profile, profile._id);
    }
  };

  const [imageUploading, setImageUploading] = useState(false);

  const handleImageSelect = (e) => {
    console.log(e);
    if (e.target.files.length > 0) {
      uploadImage([...e.target.files]);
    }
  };

  const callbackImagesComplete = (url) => {
    setProfile({ ...profile, photoURL: url });
    setImageUploading(false);
  };

  const callbackImagesProgress = (snapshot, imageName) => {
    setImageUploading(true);
  };

  const uploadImage = (selectedImages) =>
    uploadImageOnFirestore(
      selectedImages,
      callbackImagesComplete,
      callbackImagesProgress,
      { maxHeight: 160, maxWidth: 160, minHeight: 95, minWidth: 95 },
    );

  const [profileChanged, setProfileChanged] = useState(
    JSON.stringify(profile) !==
      JSON.stringify(getSubscriberData(props.subscriber)),
  );

  useEffect(() => {
    setProfileChanged(
      JSON.stringify(profile) ===
        JSON.stringify(getSubscriberData(props.subscriber)),
    );
  });

  const cancelUpdate = () => {
    setProfile(getSubscriberData(props.subscriber));
  };

  return (
    <TabPane tabId="5">
      <Spin spinning={imageUploading} tip="Uploading...">
        <div>
          {props.back && (
            <Icon
              onClick={props.back}
              type="arrow-left"
              style={{ fontSize: "28px", cursor: "pointer", color: "InfoText" }}
              className="pt-2"
            />
          )}
          <Row className="mx-auto">
            <Avatar
              size="large"
              className="mt-2 mb-2 mx-auto"
              icon="user"
              src={profile.photoURL}
            />
          </Row>
          <Row>
            {Object.keys(initSubscriberData).map((key, index) => (
              <Col md={12}>
                <InputField
                  autoFocus={index === 0}
                  type="text"
                  value={profile[key]}
                  placeholder={`Type your ${key}`}
                  id={key}
                  onChange={(key, value) =>
                    setProfile({ ...profile, [key]: value })
                  }
                  disabled={["photoURL"].includes(key)}
                />
              </Col>
            ))}
          </Row>
          <Row className="mx-auto">
            <Col>
              <InputField
                type="file"
                accept="image/*"
                placeholder={`Update Avatar Image`}
                id={"Update Avatar Image"}
                onChange={handleImageSelect}
                isPhoto
              />
            </Col>
            <Col>
              <Button
                type="default"
                loading={loading}
                className="mb-3 float-right"
                disabled={profileChanged}
                onClick={updateProfile}
              >
                Submit
              </Button>
              {!profileChanged && (
                <Button
                  type="danger"
                  className=" mr-1 mb-3 float-right"
                  disabled={profileChanged}
                  onClick={cancelUpdate}
                >
                  Cancel
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Spin>
    </TabPane>
  );
};

export default AddNewSubscriber;
