import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AddInventoryTab from "./AddInventoryTab";

export default function UpdateInventoryModal(props) {
  const { isOpen, toggle, ...rest } = props;
  return (
    <Modal size="lg" isOpen={isOpen} style={{minWidth:"90vw"}} toggle={toggle}>
      <ModalHeader toggle={toggle}>Update Inventory</ModalHeader>
      <ModalBody>
        <AddInventoryTab {...rest} />
      </ModalBody>
    </Modal>
  );
}
