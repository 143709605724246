import React, { Component } from "react";
import { Layout, Menu, Avatar } from "antd";
import { NavLink } from "react-router-dom";
import SubMenu from "antd/lib/menu/SubMenu";
const { Header } = Layout;
class NavbarAuth extends Component {
  state = {};
  render() {
    return (
      <>
        <Layout className="layout">
          <Header>
            <div className="logo">
              <a href="/">
                <img
                  src={this.props.Logo}
                  height="45px"
                  alt="AutoCityGroup.com"
                />
              </a>
            </div>
            <Menu theme="dark" mode="horizontal">
              {this.props.navItems.map(({ text = "", link = "" }) => (
                <Menu.Item className="mt-2" key={`nav-items-${text}`}>
                  <NavLink to={link} exact>
                    {text}
                  </NavLink>
                </Menu.Item>
              ))}
              {this.props.user && (
                <Menu.Item>
                  <NavLink to="/" exact onClick={this?.props?.logout}>
                    Signout
                  </NavLink>
                </Menu.Item>
              )}
              {!!this.props.subscriber && (
                <SubMenu
                  key="sub-menu-1"
                  className="float-right"
                  title={
                    <Avatar
                      className="mt-3"
                      size="default"
                      icon="user"
                      alt={this.props.subscriber.displayName}
                      src={this.props.subscriber.photoURL}
                    />
                  }
                >
                  <Menu.Item
                    key="1"
                    className="user-select-none"
                    onClick={this.props.toggleProfileModal}
                  >
                    Profile
                  </Menu.Item>
                </SubMenu>
              )}
            </Menu>
          </Header>
        </Layout>
      </>
    );
  }
}

export default NavbarAuth;
