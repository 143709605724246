import { Avatar, Button, List } from "antd";
import React, { Component } from "react";
import { TabPane } from "reactstrap";
import { searchInObject } from "../../common/DumpFunctions";
import InputField from "../../common/InputField";
import AddNewSubscriber from "./AddSubscriberTab";

export default class SubscribersList extends Component {
  state = { data: [], search: "", loading: true, editMode: false };

  componentDidMount() {
    if (this.props.getSubscribers) {
      this.props.getSubscribers("", (data) =>
        this.setState({ data, loading: false }),
      );
    }
  }

  onChangeSearch = (search) => {
    this.setState({ search });
  };

  toggleMode = (subscriber) =>
    this.setState({ editMode: !this.state.editMode, subscriber });

  renderList = () => {
    let { data, search, loading } = this.state;
    if (search) data = searchInObject(search, data);
    return (
      <>
        <InputField
          type="text"
          placeholder={`Search subscriber . . .`}
          value={search}
          autoFocus
          onChange={(_, value) => this.onChangeSearch(value)}
          badge={data.length}
        />
        <List
          itemLayout="horizontal"
          dataSource={data}
          loading={loading}
          renderItem={(item) => (
            <List.Item
              actions={[
                <a key="list-edit" onClick={() => this.toggleMode(item)}>
                  edit
                </a>,
                <a
                  key="list-delete"
                  onClick={() => this.props.deleteSubscriber(item._id)}
                >
                  delete
                </a>,
              ]}
            >
              <List.Item.Meta
                avatar={<Avatar src={item.photoURL} icon="user" />}
                title={
                  <span className="user-select-all">{item.displayName}</span>
                }
                description={
                  <span className="user-select-all">{item.email}</span>
                }
              />
              <div className="d-flex justify-content-between w-100">
                <span className="user-select-none"></span>
                <span className="mr-1 user-select-all">{item.phoneNumber}</span>
                <span className="mr-1 user-select-all">{item.address}</span>
                <span className="user-select-all">{item.createdAt}</span>
                <span className="user-select-none"></span>
              </div>
              <div></div>
            </List.Item>
          )}
        />
      </>
    );
  };

  renderUpdate = (subscriber) => {
    return (
      <>
        <AddNewSubscriber
          updateSubscriber={this.props.updateSubscriber}
          subscriber={subscriber}
          back={this.toggleMode}
        />
      </>
    );
  };

  render() {
    let { editMode, subscriber } = this.state;
    return (
      <TabPane tabId="4" className="user-select-none">
        {editMode ? this.renderUpdate(subscriber) : this.renderList()}
      </TabPane>
    );
  }
}
