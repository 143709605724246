import React from 'react';
import { Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';
import Carousel from 'react-gallery-carousel';
import {
  initCarData,
  CarCardInfoValues,
  imageNotAvailableURL,
  soldImageURL,
  getRandomTagColor,
} from './Constants';
import { car_card_image, car_card_image_small } from './cssObjects';
import { showDealerPrice } from './DumpFunctions';
import { Button, Tag } from 'antd';
const CarCard = (props) => {
  let { car, information, small = true } = props;
  car = { ...initCarData, ...car };
  return (
    <Card>
      <div>
        {car.Photos.length > 0 ? (
          <Carousel
            key={car._id}
            images={car.Photos.map((src) => ({ src }))}
            style={small ? car_card_image_small : car_card_image}
            {...(car.Photos.length <= 1 && {
              hasRightButton: true,
              hasLeftButton: true,
            })}
            shouldMinimizeOnClick
            hasThumbnails={false}
            hasMediaButton={false}
            hasSizeButton="topRight"
            canAutoPlay={false}
            hasIndexBoard={false}
          />
        ) : (
          <CardImg
            top
            style={small ? car_card_image_small : car_card_image}
            src={imageNotAvailableURL}
            alt={car.Name}
          />
        )}
        {car.Sold && (
          <img
            width='50%'
            style={{ position: 'absolute', top: -40, right: 0 }}
            src={soldImageURL}
            alt='Sold'
          />
        )}
      </div>
      <CardBody className='p-1'>
        <CardTitle tag={!small ? 'h5' : 'h6'}>
          {car.Year} {car.Name} - {car.Model}
        </CardTitle>
        {showDealerPrice(car, information)}
        {!small && (
          <CardText
            style={{
              height: 50,
              overflow: 'hidden',
            }}
          >
            {CarCardInfoValues.filter(
              ({ id }) => Object.keys(car).includes(id) && !!car[id]
            ).map(({ id, icon }) => (
              <Tag
                key={`tag-${id}`}
                color={`${getRandomTagColor()}`}
                className='rounded-pill mb-1'
              >
                <i
                  className={`fa fa-${icon} user-select-none`}
                >{` ${id}:  `}</i>
                <b color='black'>{car[id]}</b>
              </Tag>
            ))}
          </CardText>
        )}
        <Button
          className='waves-effect w-100'
          onClick={() => props.history.push(`/car/${car._id}`)}
        >
          View More Details
        </Button>
      </CardBody>
    </Card>
  );
};
export default CarCard;
