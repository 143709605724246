import { Avatar, Button, notification, Spin, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { initSubscriberData } from "../../common/Constants";
import InputField from "../../common/InputField";
import { Auth, uploadImageOnFirestore } from "../../firebase";

const ProfileModal = (props) => {
  const getSubscriberData = (data) => {
    if (!data) data = {};
    let {
      displayName = "",
      phoneNumber = "",
      email = "",
      photoURL = "",
      address = "",
    } = data;
    data = {
      displayName,
      phoneNumber,
      email,
      photoURL,
      address,
    };
    Object.keys(data).forEach((key) => {
      if (!data[key]) data[key] = "";
    });
    return data;
  };

  const [profile, setProfile] = useState(getSubscriberData(props.subscriber));

  const [loading, setLoading] = useState(false);

  const [imageUploading, setImageUploading] = useState(false);

  const updateProfile = () => {
    setLoading(true);
    let user = Auth.currentUser;
    user
      .updateProfile({
        ...profile,
      })
      .then((e) => {
        console.log(e);
        setLoading(false);
        props.updateSubscriber({ ...props.subscriber, ...profile });
        props.onUpdateSubscriber(profile, user.uid);
      })
      .catch((er) => {
        console.log(er);
        setLoading(false);
        setProfile(getSubscriberData({ ...props.subscriber }));
      });
  };

  const handleImageSelect = (e) => {
    console.log(e);
    if (e.target.files.length > 0) {
      uploadImage([...e.target.files]);
    }
  };

  const callbackImagesComplete = (url) => {
    setProfile({ ...profile, photoURL: url });
    setImageUploading(false);
  };

  const callbackImagesProgress = (snapshot, imageName) => {
    setImageUploading(true);
  };

  const uploadImage = (selectedImages) =>
    uploadImageOnFirestore(
      selectedImages,
      callbackImagesComplete,
      callbackImagesProgress,
      { maxHeight: 160, maxWidth: 160, minHeight: 95, minWidth: 95 },
    );

  const [profileChanged, setProfileChanged] = useState(
    JSON.stringify(profile) !==
      JSON.stringify(getSubscriberData(props.subscriber)),
  );

  useEffect(() => {
    setProfileChanged(
      JSON.stringify(profile) ===
        JSON.stringify(getSubscriberData(props.subscriber)),
    );
  });

  const toggle = () => {
    if (!profileChanged) {
      notification.warning({
        message: "Warning",
        description: `There are some unsaved changes`,
      });
    } else {
      props.toggle();
    }
  };

  const cancelUpdate = () => {
    setProfile(getSubscriberData(props.subscriber));
  };

  return (
    <Modal isOpen={props.isOpen} toggle={toggle}>
      <Spin spinning={imageUploading} tip="Uploading...">
        <ModalHeader toggle={toggle}>Profile Settings</ModalHeader>
        <ModalBody>
          <Row className="mx-auto">
            <Avatar
              size="large"
              className="mt-2 mb-2 mx-auto"
              icon="user"
              src={profile.photoURL}
            />
          </Row>
          <Row>
            {Object.keys(initSubscriberData).map((key) => (
              <Col md={12}>
                <InputField
                  type="text"
                  value={profile[key]}
                  placeholder={`Type your ${key}`}
                  id={key}
                  onChange={(key, value) =>
                    setProfile({ ...profile, [key]: value })
                  }
                  disabled={["email", "photoURL"].includes(key)}
                />
              </Col>
            ))}
          </Row>
          <Row className="mx-auto">
            <Col>
              <InputField
                type="file"
                accept="image/*"
                placeholder={`Update Avatar Image`}
                id={"Update Avatar Image"}
                onChange={handleImageSelect}
                isPhoto
              />
            </Col>
            <Col>
              <Button
                type="default"
                loading={loading}
                className="mb-3 float-right"
                disabled={profileChanged}
                onClick={updateProfile}
              >
                Update
              </Button>
              {!profileChanged && (
                <Button
                  type="danger"
                  className=" mr-1 mb-3 float-right"
                  disabled={profileChanged}
                  onClick={cancelUpdate}
                >
                  Cancel
                </Button>
              )}
            </Col>
          </Row>
        </ModalBody>
      </Spin>
    </Modal>
  );
};

export default ProfileModal;
