import React, { useState } from "react";
import { FormGroup, Label, Input, Badge } from "reactstrap";
import Autosuggest from "react-autosuggest";
import { filterStringArray } from "./DumpFunctions";
const InputField = (props) => {
  let {
    type = "text",
    placeholder = "",
    value = "",
    id = "",
    onChange = (_) => {},
    fullData = [],
    badge,
    ...rest
  } = props;

  let focus = true;

  if (id.includes("Time")) {
    type = "time";
  }
  if (type === "string") {
    type = "text";
  }

  const onChangeInput = (e, e2) => {
    if (rest.multiple || rest.isPhoto) {
      onChange(e);
    } else {
      let {
        target: { value },
      } = e;
      onChange(id, e2?.newValue || value);
    }
  };

  const [suggestions, setSuggestions] = useState([]);

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(
      filterStringArray(
        fullData.map((d) => d[id]),
        value,
      ),
      // .slice(0, 10)
    );
  };

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const getSuggestionValue = (suggestion) => {
    return suggestion;
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  if ((value + "").length > 50) {
    type = "textarea";
    value = value
      .split("\n")
      .map((v) => "• " + v.replaceAll("• ", ""))
      .join("\n");
  }

  const inputProps = {
    placeholder,
    value: value + "",
    type,
    className: "form-control",
    onChange: onChangeInput,
  };
  return (
    <FormGroup>
      {!!id && (
        <Label style={{ userSelect: "none" }}>
          {id.toUpperCase()}{" "}
          {!!rest.image && <img src={rest.image} height="20px" />}
        </Label>
      )}
      {fullData.length > 0 ? (
        <Autosuggest
          suggestions={suggestions}
          shouldRenderSuggestions={() => true}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          renderInputComponent={(inputProps) => <Input {...inputProps} />}
        />
      ) : (
        <>
          <Input
            className={!!badge ? "pl-5 mt-1" : "mt-1"}
            // autoFocus={focus}
            type={type}
            value={value}
            onChange={onChangeInput}
            placeholder={placeholder}
            {...rest}
          />
          {!!badge && (
            <Badge
              color="info pt-2 pb-2 ml-1"
              style={{ position: "absolute", marginTop: -33 }}
            >
              {badge}
            </Badge>
          )}
        </>
      )}
    </FormGroup>
  );
};
export default InputField;
