import React from "react";
import AddInventoryTab from "./InventoryTabs/AddInventoryTab";
import DisplayInventoryTab from "./InventoryTabs/DisplayInventoryTab";
import { TabContent, Nav, NavItem, NavLink, Badge } from "reactstrap";
import classnames from "classnames";
import { Storage, uploadImageOnFirestore } from "../firebase";
import { resizeImage, searchInObject } from "../common/DumpFunctions";
import InputField from "../common/InputField";
import UpdateInventoryModal from "./InventoryTabs/UpdateInventoryModal";
import InfoTab from "./InfoTabs/InfoTab";
import { WithContext as ReactTags } from "react-tag-input";
import { initCarData, initInfoData, TabsAdmin } from "../common/Constants";
import SubscribersList from "./SubscribersTab/SubscribersList";
import AddNewSubscriber from "./SubscribersTab/AddSubscriberTab";

const initState = {
  selectedId: null,
  isOpen: false,
  search: "",
  data: { ...initCarData },
  infoData: { ...initInfoData },
  progress: {},
};
export default class Home extends React.Component {
  state = {
    activeTab: this.props.activeTab || "1",
    ...initState,
  };
  toggleActiveTab = (activeTab) => {
    if (this.state.activeTab !== activeTab) {
      let updateState = { activeTab };
      if (activeTab == 3) {
        updateState.infoData = {
          ...this.state.infoData,
          ...JSON.parse(JSON.stringify(this.props.information)),
        };
      } else {
        this.props.setActiveTab(activeTab);
      }
      this.setState({ ...updateState });
    }
  };

  onChangeAddCar = (key, value) => {
    let data = { ...this.state.data, [key]: value };
    if ("Mileage" === key) {
      // control other input
      if (isNaN(value)) return;
      if (value !== "") {
        data.Kilometres = parseFloat(value) * 1.609344;
        data.Kilometres = data.Kilometres.toFixed(2).toString();
      } else {
        data.Kilometres = "";
      }
    }
    if ("Kilometres" === key) {
      // control other input
      if (isNaN(value)) return;
      if (value !== "") {
        data.Mileage = parseFloat(value) / 1.609344;
        data.Mileage = data.Mileage.toFixed(2).toString();
      } else {
        data.Mileage = "";
      }
    }
    this.setState({ data });
  };

  onChangeInfo = (key, value) => {
    this.setState({ infoData: { ...this.state.infoData, [key]: value } });
  };

  onChangeSearch = (search) => {
    this.setState({ search });
  };

  handleImageSelect = (e) => {
    if (e.target.files.length > 0) {
      this.uploadImage([...e.target.files]);
    }
  };

  callbackImagesComplete = (url) => {
    let stateUpdate = {};
    if (this.state.activeTab == 3) {
      stateUpdate.infoData = {
        ...this.state.infoData,
        SliderImages: [...this.state.infoData.SliderImages, url],
      };
    } else {
      stateUpdate.data = {
        ...this.state.data,
        Photos: [...this.state.data.Photos, url],
      };
    }
    this.setState({
      ...stateUpdate,
    });
  };

  callbackImagesProgress = (snapshot, imageName) => {
    const progress = Math.round(
      (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
    );
    this.setState({
      progress: { ...this.state.progress, [imageName]: progress },
    });
  };

  uploadImage = (selectedImages) =>
    uploadImageOnFirestore(
      selectedImages,
      this.callbackImagesComplete,
      this.callbackImagesProgress,
    );

  toggle = (selectedId, isOpen) => {
    let data = {
      ...initCarData,
    };
    if (isOpen && selectedId) {
      data = {
        ...data,
        ...JSON.parse(
          JSON.stringify(this.props.allData.find((d) => d._id === selectedId)),
        ),
      };
      this.setState({ data });
    }
    this.setState({ isOpen, selectedId, data });
  };

  renderReactTags = (data, key, placeholder) => {
    return (
      <ReactTags
        autofocus={false}
        key={`suggested-sku-${this.state.activeTab}-${key}`}
        classNames={{
          remove: "removeTag",
          tagInputField: "form-control",
          tagInput: "inputTag",
        }}
        placeholder={placeholder}
        inline
        allowDragDrop
        tags={data.map((text) => ({
          id: text,
          text,
        }))}
        handleAddition={(tag) => this.handleAddition(tag, key)}
        handleDelete={(i) => this.handleDelete(i, key)}
        handleDrag={(tag, currPos, newPos) =>
          this.handleDrag(tag, currPos, newPos, key)
        }
      />
    );
  };

  handleDelete = (i, key) => {
    const selectedtag = this.state.infoData[key];
    this.setState({
      infoData: {
        ...this.state.infoData,
        [key]: selectedtag.filter((tag, index) => index !== i),
      },
    });
  };

  handleAddition = (tag, key) => {
    const selectedtag = this.state.infoData[key];
    this.setState({
      infoData: {
        ...this.state.infoData,
        [key]: [...selectedtag, tag.text],
      },
    });
  };

  handleDrag = (tag, currPos, newPos, key) => {
    let selectedtag = this.state.infoData[key];
    // mutate array
    selectedtag.splice(currPos, 1);
    selectedtag.splice(newPos, 0, tag.text);
    // re-render
    this.setState({
      infoData: { ...this.state.infoData, [key]: selectedtag },
    });
  };

  renderReorder = (data, dataKey, PhotosKey, onChangeAddCar) => {
    return (
      <ReactTags
        key={`suggested-sku-${dataKey}`}
        classNames={{
          remove: "removeClass",
          tagInput: "tagInputClass",
        }}
        inline
        allowDragDrop
        tags={data.map((photo) => ({
          id: photo,
          photo,
          text: (
            <div>
              <i
                onClick={() => {
                  if (window.confirm("Are you sure?")) {
                    onChangeAddCar(
                      PhotosKey,
                      this.state[dataKey][PhotosKey].filter((i) => i !== photo),
                    );
                  }
                }}
                className="fa fa-times"
                style={{
                  fontSize: 14,
                  color: "red",
                  cursor: "pointer",
                  position: "absolute",
                }}
              />
              <img src={photo} height="40px" />
            </div>
          ),
        }))}
        handleDrag={(tag, currPos, newPos) =>
          this.handleDragImage(tag, currPos, newPos, dataKey, PhotosKey)
        }
      />
    );
  };

  handleDragImage(tag, currPos, newPos, dataKey, PhotosKey) {
    const photos = this.state[dataKey][PhotosKey];
    // mutate array
    photos.splice(currPos, 1);
    photos.splice(newPos, 0, tag.photo);
    // re-render
    this.setState({
      [dataKey]: { ...this.state[dataKey], [PhotosKey]: photos },
    });
  }

  render() {
    let { allData, apiCallLoading } = this.props;
    let {
      search,
      data,
      infoData,
      activeTab,
      progress,
      isOpen,
      selectedId,
    } = this.state;

    let fullData = [...allData];
    let count = fullData.length;
    if (search) allData = searchInObject(search, allData);
    let renderTagInput = this.renderReorder;
    let renderReactTags = this.renderReactTags;

    return (
      <div className="ml-3 mr-3 row">
        <Nav tabs vertical className="col-md-2 mt-1">
          <NavTabs
            count={count}
            activeTab={activeTab}
            toggleActiveTab={this.toggleActiveTab}
            data={TabsAdmin}
          />
          {activeTab === "2" && (
            <span className="w-100">
              <InputField
                type="text"
                placeholder={`Search Something . . .`}
                value={search}
                autoFocus
                onChange={(_, value) => this.onChangeSearch(value)}
                badge={allData.length}
              />
            </span>
          )}
        </Nav>
        <TabContent activeTab={activeTab} className="col">
          <AddInventoryTab
            props={{
              data,
              progress,
              addCar: this.props.addCar,
              handleImageSelect: this.handleImageSelect,
              onChangeAddCar: this.onChangeAddCar,
              reset: () => this.setState({ ...initState }),
              apiCallLoading,
              renderTagInput,
              fullData,
            }}
          />
          <UpdateInventoryModal
            isOpen={isOpen}
            toggle={this.toggle}
            props={{
              data,
              progress,
              addCar: (data, type) => {
                this.props.onUpdateCar(data, selectedId, type);
              },
              handleImageSelect: this.handleImageSelect,
              onChangeAddCar: this.onChangeAddCar,
              reset: () => this.setState({ ...initState }),
              apiCallLoading,
              renderTagInput,
              fullData,
            }}
          />
          <DisplayInventoryTab
            props={{
              toggle: this.toggle,
              isOpen: isOpen,
              allData,
              deleteCar: this.props.deleteCar,
              apiCallLoading,
              renderTagInput,
            }}
          />
          <InfoTab
            props={{
              data: infoData,
              progress,
              addCar: (infoData, type) => {
                this.props.onUpdateInfo(infoData, infoData._id, "info");
              },
              handleImageSelect: this.handleImageSelect,
              onChangeAddCar: this.onChangeInfo,
              // reset: () => this.setState({ ...initState }),
              tabId: "3",
              apiCallLoading,
              renderTagInput,
              renderReactTags,
            }}
          />
          {activeTab === "4" && (
            <SubscribersList
              deleteSubscriber={this.props.deleteSubscriber}
              getSubscribers={this.props.getSubscribers}
              updateSubscriber={this.props.updateSubscriber}
            />
          )}
          <AddNewSubscriber
            addSubscriber={this.props.addSubscriber}
            subscriber={{}}
          />
        </TabContent>
      </div>
    );
  }
}

const NavTabs = ({ activeTab, toggleActiveTab, data, count = 0 }) =>
  data.map(({ text, number }) => (
    <NavItem
      style={{ cursor: "pointer", userSelect: "none" }}
      key={`Progress-${number}`}
    >
      <NavLink
        className={classnames({ active: activeTab === number })}
        onClick={() => {
          toggleActiveTab(number);
        }}
      >
        {text} {number === "2" ? <Badge>{count}</Badge> : ""}
      </NavLink>
    </NavItem>
  ));
