import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";
import { Icon, notification } from "antd";
import { resizeImage } from "../common/DumpFunctions";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBjTtlAJ53KxeiBgLrVDzmleZJODTRjmps",
  authDomain: "autocitygroup-1c9af.firebaseapp.com",
  projectId: "autocitygroup-1c9af",
  storageBucket: "autocitygroup-1c9af.appspot.com",
  messagingSenderId: "253806716355",
  appId: "1:253806716355:web:25e221e5b8c801e6f28976",
  measurementId: "G-VD8W16VLQV",
};
// Initialize Firebase
if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);
var GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();

const actionCodeSettings = (url) => {
  return {
    url,
    handleCodeInApp: true,
  };
};

const Storage = firebase.storage();
const Auth = firebase.auth();

const signInWithEmailLink = (callback = (_) => {}) => {
  if (Auth.isSignInWithEmailLink(window.location.href)) {
    let email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
      email = window.prompt("Please provide your email for confirmation");
    }
    firebase
      .auth()
      .signInWithEmailLink(email, window.location.href)
      .then((result) => {
        window.localStorage.removeItem("emailForSignIn");
        callback(result.user);
      });
  }
};

const sendSignInLinkToEmail = (email, url) => {
  firebase
    .auth()
    .sendSignInLinkToEmail(email, actionCodeSettings(url))
    .then(() => {
      window.localStorage.setItem("emailForSignIn", email);
      notification.open({
        message: "Successfully Subscribed",
        description: `Thanks for subscribing our newsletter. Please confirm your email address (${email}) by visiting`,
        icon: (
          <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
        ),
      });
    })
    .catch((error) => {
      notification.error({
        message: error.code,
        description: error.message,
        icon: (
          <Icon type="FrownOutlined" theme="twoTone" twoToneColor="#52c41a" />
        ),
      });
    });
};

const signInWithGmail = (callback = (_) => {}) => {
  firebase
    .auth()
    .signInWithPopup(GoogleAuthProvider)
    .then((result) => {
      notification.open({
        message: "Successfully Subscribed",
        description: `Thanks you Mr.${result.user.displayName} for subscribing our newsletter.`,
        icon: (
          <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
        ),
      });
      callback(result.user);
    })
    .catch((error) => {
      notification.error({
        message: error.code,
        description: error.message,
        icon: (
          <Icon type="FrownOutlined" theme="twoTone" twoToneColor="#52c41a" />
        ),
      });
    });
};

const onAuthStateChanged = (callback) => Auth.onAuthStateChanged(callback);

const uploadImageOnFirestore = async (
  selectedImages = [],
  callbackComplete = (url) => console.log(url),
  callbackProgress = (snapshot, name) =>
    console.log(snapshot.bytesTransferred / snapshot.totalBytes, name),
  dimensions = {},
) => {
  let images = [];
  for (let i = 0; i < selectedImages.length; i++) {
    let image = selectedImages[i];
    image = await resizeImage(image, dimensions);
    images.push(image);
  }
  images.forEach((image) => {
    let now = Date.now();
    let imageName = `images/${image.name + "-" + now}`;
    const uploadTask = Storage.ref(imageName).put(image);
    uploadTask.on(
      "state_changed",
      (snap) => callbackProgress(snap, imageName),
      (error) => {
        console.error(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(callbackComplete);
      },
    );
  });
};
export {
  firebase as default,
  Storage,
  Auth,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  onAuthStateChanged,
  uploadImageOnFirestore,
  signInWithGmail,
};
