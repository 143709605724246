import React from "react";
import Auth from "./Auth";

const Navbar = (props) => {
  return <NavbarAuth {...props} />;
};

const NavbarAuth = (props) => {
  return (
    <Auth
      navItems={props.navItems}
      Logo={props.info.Logo}
      user={props.user}
      logout={props.logout}
      subscriber={props.subscriber}
      toggleProfileModal={props.toggleProfileModal}
    />
  );
};

export default Navbar;
