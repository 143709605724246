import React from 'react';
import { TabPane, Table, Row, Button } from 'reactstrap';
import { imageNotAvailableURL } from '../../common/Constants';
import { car_list_image } from '../../common/cssObjects';
const displayProperties = [
  'Photos',
  'Name',
  'Model',
  'Year',
  'Mileage',
  'Transmission',
  'DealerPrice',
  'VIN',
  'Kilometres',
  'Condition',
  'FuelType',
  'Action',
];
const DisplayInventoryTab = ({
  props: { allData, deleteCar, toggle, isOpen },
}) => (
  <TabPane tabId='2'>
    <Row>
      <Table striped responsive hover>
        <thead>
          <tr>
            {displayProperties.map((col) => (
              <th key={`heading-${col}`}>{col}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allData
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((row) => (
              <tr key={`table-row-${row._id}`}>
                {displayProperties.map((col) => {
                  if (col === 'Photos')
                    return (
                      <td key={`table-col-${col}`}>
                        <img
                          src={row[col][0] || imageNotAvailableURL}
                          style={car_list_image}
                          onClick={() => window.open(row[col][0], '_blank')}
                        />
                      </td>
                    );
                  if (col === 'Action')
                    return (
                      <Button
                        key={`table-col-${col}`}
                        onClick={() => {
                          if (window.confirm('Are you sure?'))
                            deleteCar(row._id, 'car');
                        }}
                        className='btn-danger mt-1'
                      >
                        <i className='fa fa-trash' aria-hidden='true'></i>
                      </Button>
                    );
                  return (
                    <td
                      onClick={() => toggle(row._id, !isOpen)}
                      key={`table-col-${col}`}
                    >
                      {row[col]}
                    </td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </Table>
    </Row>
  </TabPane>
);
export default DisplayInventoryTab;
