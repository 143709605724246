export const car_card_image = {
  objectFit: "cover",
  width: "100%",
  height: window.innerWidth <= 760 ? "300px" : "450px",
};

export const car_card_image_small = {
  objectFit: "cover",
  width: "100%",
  height: "200px",
};

export const car_detail_card_image = {
  objectFit: "cover",
  width: "100%",
  height: window.innerWidth <= 760 ? "40vh" : "80vh",
};

export const car_list_image = { //admin
  objectFit: "cover",
  width: "40px",
  height: "25px",
};

export const home_slider = {
  objectFit: "cover",
  height: window.innerWidth <= 760 ? "30vh" : "75vh",
};

export const car_Inventory_image = {
  objectFit: "cover",
  width: "450px",
};
