import React from 'react';
import { TabPane, Button, Row, Col, Progress, Badge } from 'reactstrap';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import InputField from '../../common/InputField';
import ReactLocationPicker from '../../common/ReactLocationPicker';
import { sliders } from '../../common/Constants';
import { car_Inventory_image } from '../../common/cssObjects';
import { notification } from 'antd';
const AddInventoryTab = ({
  props: {
    data,
    progress,
    addCar,
    handleImageSelect,
    onChangeAddCar,
    reset = (_) => {},
    tabId = '1',
    apiCallLoading = false,
    renderTagInput = (_) => {},
    renderReactTags = (_) => {},
    fullData = [],
  },
}) => {
  let PhotosKey = data?.Photos ? 'Photos' : 'SliderImages';
  let dataKey = 'data';
  let submitButtonText = 'Submit Inventory';

  let isDisabled = false;
  if (PhotosKey === 'Photos') {
    isDisabled = !!!data?.Name;
    // isDisabled = isDisabled || data?.Photos?.length === 0;
  } else {
    submitButtonText = 'Update';
    dataKey = 'infoData';
  }
  isDisabled =
    isDisabled ||
    apiCallLoading ||
    Object.values(progress).find((value) => value < 100);

  let xs = 12;
  let sm = 6;
  let md = 4;
  let lg = 4;

  return (
    <TabPane tabId={tabId}>
      <div className='d-flex justify-content-center'>
        <div style={car_Inventory_image}>
          <ImageGallery
            showBullets
            showThumbnails={false}
            showPlayButton={false}
            showFullscreenButton={false}
            items={data[PhotosKey].map((p) => ({
              original: p,
              thumbnail: p,
            }))}
          />
        </div>
      </div>
      <div className='d-flex justify-content-center'>
        {renderTagInput(data[PhotosKey], dataKey, PhotosKey, onChangeAddCar)}
      </div>
      <RenderProgressBar progress={progress} />
      <Row>
        {Object.keys(data)
          .filter(
            (id) =>
              ![
                'Photos',
                'SliderImages',
                'Location',
                'Phones',
                'Fax',
                '_id',
                'createdAt',
                'SelectedSlider',
                'Sold',
                'HomePage',
                'show',
                'Captions',
              ].includes(id)
          )
          .map((id) => {
            return (
              <Col xs={xs} sm={sm} md={md} lg={lg} key={`Input-${id}`}>
                <InputField
                  fullData={fullData}
                  type={typeof data[id]}
                  placeholder={`Enter ${id}`}
                  value={data[id]}
                  id={id}
                  disabled={['createdAt', '_id'].includes(id)}
                  onChange={onChangeAddCar}
                  image={id === 'Logo' ? data[id] : undefined}
                />
              </Col>
            );
          })}
        {['Phones', 'Fax', 'Captions'].map((id) => {
          return (
            <Col xs={xs} sm={sm} md={md} lg={lg} key={`renderReactTags-${id}`}>
              {renderReactTags(data[id], id, `Enter ${id}`)}
            </Col>
          );
        })}
        {Object.keys(data)
          .filter((id) => ['Location'].includes(id))
          .map((id) => {
            return (
              <Col
                xs={xs}
                sm={sm}
                md={md}
                lg={lg}
                key={`ReactLocationPicker-${id}`}
              >
                <ReactLocationPicker
                  initialPosition={data[id]}
                  onChange={(location) => onChangeAddCar(id, location.position)}
                />
              </Col>
            );
          })}
        <Col xs={xs} sm={sm} md={md} lg={lg}>
          <InputField
            type='file'
            accept='image/*'
            placeholder={`Select Images`}
            id={data.Photos ? 'Select Images' : 'Select Slider Images'}
            onChange={handleImageSelect}
            multiple
          />
          {Object.keys(data)
            .filter((id) =>
              ['SelectedSlider', 'Sold', 'HomePage', 'show'].includes(id)
            )
            .map((id) => {
              if (id === 'SelectedSlider') {
                return sliders.map((slider) => (
                  <Badge
                    key={`Badge-${slider}`}
                    color={data[id] === slider ? 'success' : 'danger'}
                    className='pt-2 m-1 user-select-none'
                    onClick={() => onChangeAddCar(id, slider)}
                    style={{ cursor: 'pointer' }}
                  >
                    {slider}
                  </Badge>
                ));
              }
              let isDisabled = false;
              let value = data[id];
              if (id === 'HomePage') {
                isDisabled = data.Photos.length === 0 || !data.DealerPrice;
                if (isDisabled) value = false;
              }

              return (
                <Badge
                  key={`Input-${id}`}
                  color={value ? 'success' : 'danger'}
                  className='pt-2 m-1 user-select-none'
                  onClick={() => {
                    if (isDisabled) {
                      const description = `Please add ${
                        data.Photos.length === 0 ? 'images' : ''
                      } ${
                        !data.DealerPrice ? 'Dealer Price' : ''
                      } to add this car to home page`;
                      return notification.error({
                        message: 'Error',
                        description,
                      });
                    }
                    onChangeAddCar(id, !value);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {id}
                </Badge>
              );
            })}
        </Col>
      </Row>
      <Row className='d-flex justify-content-end mr-2'>
        <Button
          onClick={() => {
            addCar(data, 'car');
            reset();
          }}
          className='btn-success'
          disabled={isDisabled}
        >
          {/* {apiCallLoading ? <Spinner type="grow" size="sm" color="grey" /> : ""}{" "} */}
          {submitButtonText}
        </Button>
      </Row>
    </TabPane>
  );
};
export default AddInventoryTab;
const RenderProgressBar = ({ progress }) => (
  <Progress multi className='mt-1'>
    {Object.values(progress)
      .filter((value) => value > 0 && value < 100)
      .map((value, index) => (
        <Progress
          key={`Progress-${index}`}
          bar
          color={['danger', 'warning', 'info', 'success'][parseInt(value / 25)]}
          value={value}
        />
      ))}
  </Progress>
);
