import { Button, Tag, Tooltip } from "antd";
import Resizer from "react-image-file-resizer";
var levenshtein = require("fast-levenshtein");
export const resizeImage = (
  file,
  { maxHeight, maxWidth, minHeight, minWidth },
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, // Is the file of the image which will resized.
      maxWidth || 1400, // Is the maxWidth of the resized new image.
      maxHeight || 720, // Is the maxHeight of the resized new image.
      "JPEG", // Is the compressFormat of the resized new image.
      100, // Is the quality of the resized new image.
      0, // Is the degree of clockwise rotation to apply to uploaded image.
      (uri) => {
        resolve(uri); // Is the callBack function of the resized new image URI.
      },
      "file", // Is the output type of the resized new image.
      minWidth || 720, // Is the minWidth of the resized new image.
      minHeight || 640, // Is the minHeight of the resized new image.
    );
  });
export const searchInObject = (text, allData) => {
  let data = allData.filter((data) => {
    return Object.values(data)
      .join("")
      .toLowerCase()
      .includes(text.toLowerCase());
  });
  return data;
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const levenshteinDistance = (a, b) => levenshtein.get(a, b);

export const sortBySimilarCars = (allCars = [], oneCar = {}) => {
  let result = allCars
    .map((car) => {
      let carString = Object.values(car).join("");
      let oneCarString = Object.values(oneCar).join("");
      car.levenshteinDistance = levenshteinDistance(carString, oneCarString);
      return car;
    })
    .sort((a, b) => a.levenshteinDistance - b.levenshteinDistance)
    .filter((car) => car.levenshteinDistance !== 0);
  return result;
};

export const change24to12 = (time24) => {
  var ts = time24;
  var H = +ts.substr(0, 2);
  var h = H % 12 || 12;
  h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
  var ampm = H < 12 ? " AM" : " PM";
  ts = h + ts.substr(2, 3) + ampm;
  return ts;
};

export const filterStringArray = (array = [], value = "") => {
  return array
    .map((item) => (item + "").toLowerCase().trim())
    .filter(
      (item, i, ar) =>
        ar.indexOf(item) === i &&
        item.includes(value.toLowerCase().toString()) &&
        item,
    );
};

export const CamelToSentence = (text) => {
  let result = text.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
  return result;
};

export const showDealerPrice = (car, information) => (
  <p>
    <Tag>
      {car.DealerPrice && (
        <Tooltip
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
          placement="leftTop"
          title="CAD"
          key={`price-${car._id}`}
        >
          <span className="text-primary">$ </span>
        </Tooltip>
      )}
      <b>{car.DealerPrice || "Contact for price"}</b>
    </Tag>
    <Tag className="float-right user-select-none">
      <a href={`tel:${information?.Phones[0]}`}>
        <i className="fa fa-phone" />
          Order Now!
      </a>
    </Tag>
  </p>
);
