import React, { Component } from 'react';
import { Redirect, Route, Switch, BrowserRouter, Link } from 'react-router-dom';
import Admin from './admin/Admin';
import './App.css';
import axios from 'axios';
import Home from './Home';
import CarDetail from './CarDetails';
import { initInfoData, serverURL } from './common/Constants';
import ScreenLoader from './common/ScreenLoader';
import Inventory from './Inventory';
import Map from './components/Map/Map';
import Subscribe from './components/Subscribe/Subscribe';
import NotFound from './components/NotFound';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar';
import ScrollToTop from './common/ScrollToTop';
import { onAuthStateChanged } from './firebase';
import ProfileModal from './components/Profile';
import { notification } from 'antd';
const retriesThreshold = 10;

export default class App extends Component {
  state = {
    user: null,
    subscriber: null,
    information: { ...initInfoData },
    loading: true,
    apiCallLoading: false,
    allData: [],
    activeTab: '1',
    profileModal: false,
    retries: 0,
  };

  logout = () => {
    localStorage.removeItem('user');
    this.setState({ user: null }, this.loading);
  };

  toggleProfileModal = () => {
    this.setState({ profileModal: !this.state.profileModal });
  };

  setActiveTab = (activeTab) => this.setState({ activeTab });

  login = ({ user, password }) => {
    let pass = process.env.REACT_APP_PASSWORD || 'admin';
    let username = process.env.REACT_APP_USER || 'admin';
    if (user === username && password === pass) {
      let user = username;
      this.setState({ user });
      localStorage.setItem('user', user);
    } else {
      alert('Wrong User/Password');
    }
  };

  componentDidMount = () => {
    this.loading();
    onAuthStateChanged((subscriber) => {
      this.setState({ subscriber });
      if (subscriber) {
        this.getSubscribers({ _id: subscriber.uid }, (subs) =>
          this.setState({ subscriber: { ...subs[0] } })
        );
      }
    });
  };

  loadData = async (initObject, type = 'car') => {
    try {
      let { data: allData } = await axios.get(`${serverURL[type]}/get`);
      let response = await axios.get(`${serverURL['info']}/get`);
      let information = response?.data[0] || {};
      allData.forEach((car) => delete car.__v);
      this.setState({
        allData,
        information,
        ...initObject,
      });
      localStorage.setItem('allData', JSON.stringify(allData));
      localStorage.setItem('information', JSON.stringify(information));
    } catch (error) {
      // get data from cache if retries not started
      if (this.state.retries === 0) {
        let allData =
          JSON.parse(localStorage.getItem('allData')) || this.state.allData;
        let information =
          JSON.parse(localStorage.getItem('information')) ||
          this.state.information;
        this.setState({
          allData,
          information,
          ...initObject,
        });
      }
      if (this.state.retries < retriesThreshold) {
        this.setState((prevState) => ({ retries: prevState.retries + 1 }));
        setTimeout(() => {
          this.loadData({ ...initObject, retries: 0 }, type);
        }, 250 + this.state.retries * 100);
      }
    }
  };

  getSubscribers = async (query = '', callback = (_) => {}) => {
    if (typeof query === 'object')
      query = `?${Object.keys(query)
        .map((key) => `${key}=${query[key]}`)
        .join('&')}`;
    let { data: subscribers } = await axios.get(
      `${serverURL.subscriber}/get${query}`
    );
    if (subscribers && subscribers.length > 0) callback(subscribers);
    else callback([]);
  };

  loading = () => {
    let user = localStorage.getItem('user');
    this.loadData({ user, loading: false, apiCallLoading: false });
  };

  addNew = (data, type = 'car', callback = (_) => {}) => {
    this.setState({ apiCallLoading: true }, () => {
      axios
        .post(`${serverURL[type]}/add`, data)
        .then(() => {
          if (!data._id) notification.success({ message: type + ' added' });
          this.setState({ apiCallLoading: false });
          this.loading();
          callback();
        })
        .catch((err) => {
          notification.error({
            message: type + ' cannot be added',
            description: err,
          });
          this.setState({ apiCallLoading: false }, callback);
        });
    });
  };

  onUpdate = (updatedObject, id, type = 'car') => {
    let updateState = { apiCallLoading: true };
    if (this.state.information._id === id) {
      updateState.information = { ...this.state.information, ...updatedObject };
    } else {
      updateState.allData = this.state.allData.map((data) => {
        if (data._id === id) {
          data = { ...data, ...updatedObject };
        }
        return data;
      });
    }
    this.setState(
      {
        ...updateState,
      },
      () => {
        delete updatedObject._id;
        delete updatedObject.createdAt;
        axios
          .post(`${serverURL[type]}/update/${id}`, updatedObject)
          .then(() => {
            notification.success({ message: type + ' updated' });
            this.setState({ apiCallLoading: false });
          })
          .catch((err) => {
            notification.error({
              message: type + ' cannot be updated',
              description: err,
            });
            this.setState({ apiCallLoading: false });
          });
      }
    );
  };

  delete = (id, type = 'car') => {
    this.setState(
      {
        allData: this.state.allData.filter((data) => {
          return data._id !== id;
        }),
        apiCallLoading: true,
      },
      () => {
        axios
          .delete(`${serverURL[type]}/delete/${id}`)
          .then(() => {
            notification.success({
              message: type + ' deleted',
            });
            this.setState({ apiCallLoading: false });
          })
          .catch((err) => {
            notification.error({
              message: type + ' cannot be deleted',
              description: err,
            });
            this.setState({ apiCallLoading: false });
          });
      }
    );
  };

  Layout = (props) => {
    return (
      <ScrollToTop>
        <Navbar
          toggleProfileModal={this.toggleProfileModal}
          logout={this.logout}
          user={this.state.user}
          subscriber={this.state.subscriber}
          info={this.state.information}
          navItems={[
            { link: '/', text: 'Home' },
            { link: '/cars', text: 'Vehicles' },
            {
              ...(() => {
                if (this.state.user) return { link: '/admin', text: 'Admin' };
              })(),
            },
          ]}
        />
        <Route {...props} />
        {props.noFooter === undefined && (
          <>
            <Subscribe
              information={this.state.information}
              subscriber={this.state.subscriber}
              addNewSubscriber={(data) => this.addNew(data, 'subscriber')}
            />
            <Map information={this.state.information} />
            <Footer Info={this.state.information} />
          </>
        )}
        {this.state.subscriber && (
          <ProfileModal
            subscriber={this.state.subscriber}
            updateSubscriber={(subscriber) => this.setState({ subscriber })}
            isOpen={this.state.profileModal}
            onUpdateSubscriber={(obj, id) =>
              this.onUpdate(obj, id, 'subscriber')
            }
            toggle={this.toggleProfileModal}
          />
        )}
      </ScrollToTop>
    );
  };

  render() {
    const Layout = this.Layout;
    let { loading, allData, information, apiCallLoading, user } = this.state;
    if (loading) {
      return <ScreenLoader />;
    }
    return (
      <BrowserRouter>
        <Switch>
          <Layout
            exact
            path='/'
            component={(props) => (
              <Home
                {...props}
                data={{
                  allData,
                  information,
                  apiCallLoading,
                }}
              />
            )}
          />
          <Layout
            exact
            path='/cars'
            component={(props) => (
              <Inventory
                {...props}
                data={{
                  allData,
                  information,
                  apiCallLoading,
                }}
              />
            )}
          />
          <Layout
            exact
            path='/car/:id'
            component={(props) => (
              <CarDetail
                {...props}
                data={{
                  allData,
                  information,
                  apiCallLoading,
                }}
              />
            )}
          />
          <Layout
            exact
            path='/admin'
            noFooter
            component={(props) => (
              <Admin
                {...props}
                login={this.login}
                activeTab={this.state.activeTab}
                setActiveTab={this.setActiveTab}
                data={{
                  allData,
                  information,
                  apiCallLoading,
                  user,
                }}
                addNew={this.addNew}
                delete={this.delete}
                onUpdate={this.onUpdate}
                getSubscribers={this.getSubscribers}
              />
            )}
          />
          <Route exact path='/not-found' component={NotFound} />
          <Redirect to='/not-found' />
        </Switch>
        {apiCallLoading && <ScreenLoader />}
      </BrowserRouter>
    );
  }
}
