import React from "react";
import Carousel from "react-gallery-carousel";
import {
  Card,
  CardText,
  CardTitle,
  Col,
  Row,
  UncontrolledCollapse,
  Button,
  CardBody,
  CardHeader,
} from "reactstrap";
import CarCard from "../common/CarCard";
import { car_detail_card_image } from "../common/cssObjects";
import { showDealerPrice, sortBySimilarCars } from "../common/DumpFunctions";
import CarDetailsList from "./CarDetailsList";
const CollapseProperties = [
  "Overview",
  "Mechanical",
  "Exterior",
  "Entertainment",
  "Interior",
  "Safety",
];
const similarCarsIndexes = [0, 1, 2, 3];

export default function CarDetail(props) {
  let { allData = [], information = {} } = props.data;
  let car = allData.find(({ _id }) => props?.match?.params?.id === _id);
  allData = sortBySimilarCars(allData, car);
  let SliderImages = [];
  if (!car) {
    props.history.push("/");
  } else {
    SliderImages = car.Photos.map((src, index) => ({
      src,
      original: src,
      thumbnail: src,
    }));
  }
  return (
    <div className="m-md-5">
      <Row>
        <Col xs={12} sm={6} md={7} lg={8} className="p-0 m-0">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="p-0 m-0">
              <Card>
                <CardHeader className="user-select-none" tag="h4">
                  {car.Year} {car.Name} - {car.Model}
                </CardHeader>
                <Carousel
                  style={car_detail_card_image}
                  images={SliderImages}
                  isAutoPlaying
                  hasIndexBoard={false}
                  canAutoPlay={false}
                  hasSizeButton={false}
                />
              </Card>
            </Col>
            {CollapseProperties.filter((key) => car[key]).map((key, index) => (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="p-0 m-0"
                key={`collapse-${key}`}
              >
                <Card>
                  <CardHeader id={key} className="user-select-none">
                    {key}
                  </CardHeader>
                  <UncontrolledCollapse
                    toggler={`#${key}`}
                    defaultOpen={index === 0}
                  >
                    <CardBody>
                      <ul>
                        {car[key]
                          .split("•")
                          .filter((text) => text.trim())
                          .map((text) => (
                            <li key={`li-${key}`}>{text.trim()}</li>
                          ))}
                      </ul>
                    </CardBody>
                  </UncontrolledCollapse>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
        <Col xs={12} sm={6} md={5} lg={4} className="p-0 m-0">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="p-0 m-0">
              <Card body>
                <CardTitle tag="h4">
                  {car.Year} {car.Name} - {car.Model}
                </CardTitle>
                <CardText className="rounded">
                  {showDealerPrice(car, information)}
                </CardText>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="p-0 m-0">
              <CarDetailsList car={car} />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="p-0 m-0">
              <Card>
                <CardHeader tag="h5" className="user-select-none">
                  Similar Cars
                </CardHeader>
                <Row>
                  {similarCarsIndexes.map((index) => (
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      key={`similar-cars-${index}`}
                    >
                      <CarCard
                        {...props}
                        car={allData[index]}
                        information={information}
                        small
                      />
                    </Col>
                  ))}
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
