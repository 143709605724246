import React, { Fragment, useState } from "react";
import LocationPicker from "react-location-picker";
export default function ReactLocationPicker({
  initialPosition = {},
  onChange = (_) => {},
}) {
  const [location, setLocation] = useState({});
  const onLocationChange = (location) => {
    setLocation(location);
    onChange(location);
  };
  return (
    <Fragment>
      <small className="d-flex justify-content-center">{location.address}</small>
      <LocationPicker
        containerElement={<div style={{ height: "100%" }} />}
        mapElement={<div style={{ height: "150px", width: "100%" }} />}
        defaultPosition={initialPosition}
        onChange={onLocationChange}
        circleOptions={{
          radius: 0,
        }}
      />
    </Fragment>
  );
}
