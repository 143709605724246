import React from "react";
import { Card, CardBody, Form } from "reactstrap";
export default class Login extends React.Component {
  state = { user: process.env.REACT_APP_USER_ || "", password: "" };
  render() {
    return (
      <div className="App">
        <div className="col-lg-12">
          <div className="row mt-5">
            <div className="offset-lg-4 col-lg-4 mt-5">
              <Card>
                <CardBody>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.props.login(this.state);
                    }}
                  >
                    <div className="container login-section">
                      <div className="container text-center">
                        <h3>Login</h3>
                      </div>
                      <div className="form-group">
                        <label for="">Username</label>
                        <input
                          type="text"
                          onChange={({ target: { value: user } }) =>
                            this.setState({ user })
                          }
                          value={this.state.user}
                          className="form-control"
                          placeholder="Enter Username"
                        />
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          type="password"
                          onChange={({ target: { value: password } }) =>
                            this.setState({ password })
                          }
                          value={this.state.password}
                          className="form-control"
                          placeholder="Enter Password"
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          onClick={() => this.props.login(this.state)}
                          className="btn btn-primary"
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
