import React from "react";

const Footer = ({ Info = "" }) => {
  return (
    <footer className="page-footer font-small unique-color-dark">
      <div className="footer-copyright text-center py-3">
        © 2020 Copyright:&nbsp;{Info.Name}
      </div>
    </footer>
  );
};

export default Footer;
