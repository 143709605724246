import React from "react";
import { Badge, ListGroup, ListGroupItem } from "reactstrap";
import { CamelToSentence } from "../common/DumpFunctions";
export default function CarDetailsList({ car = {} }) {
  return (
    <ListGroup>
      {Object.keys(car)
        .filter((key) =>
          [
            "VIN",
            "Kilometres",
            "Condition",
            "BodyStyle",
            "Engine",
            "StockNo",
            "ExteriorColour",
            "InteriorColour",
            "DriveTrain",
            "Doors",
            "FuelType",
          ].includes(key) && car[key]
        )
        .map((key) => {
          return (
            <ListGroupItem key={`listgroup-badges-${key}`}>
              <span className="float-left">
                <Badge color="warning">
                  <i className="fa fa-check" />
                  {CamelToSentence(key)}
                </Badge>
              </span>
              <span className="float-right">
                <Badge color="success" pill>
                  {car[key]}
                </Badge>
              </span>
            </ListGroupItem>
          );
        })}
    </ListGroup>
  );
}
