import React, { Fragment, useState } from "react";
import { Col, Row } from "reactstrap";
import Card from "../common/CarCard";
import { searchInObject } from "../common/DumpFunctions";
import InputField from "../common/InputField";

export default function Inventory(props) {
  let { allData = [], information = {} } = props.data;
  const [search, setSearch] = useState("");
  let carsToShow = allData.filter((car) => car.show);
  if (search) carsToShow = searchInObject(search, carsToShow);

  return (
    <Fragment>
      <h2 className="text-center text-gray myy">All Vehicles</h2>
      <Row className="m-md-4">
        <Col>
          <InputField
            type="text"
            placeholder={`Search any vehicle . . .`}
            value={search}
            onChange={(_, value) => setSearch(value)}
          />
        </Col>
      </Row>
      <Row className="m-md-4">
        {carsToShow.map((car) => (
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={4}
            key={`car-col-${car._id}`}
            className="mb-2"
          >
            <Card {...props} car={car} information={information} />
          </Col>
        ))}
      </Row>
    </Fragment>
  );
}
