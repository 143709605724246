import React from "react";

const Services = () => {
  return (
    <div className="py-5">
      <section className="p-md-3 mx-md-5 text-lg-left">
        <h2 className="text-center mb-5">Our Services</h2>
        <div className="row text-center d-flex justify-content-center mt-5">
          <div className="col-lg-3 col-md-6 mb-lg-0 mb-5">
            <i className="fas fa-car fa-3x indigo-text mb-4 animated pulse infinite"></i>
            <h4 className="mb-4">Car Dealer</h4>
            <p className="text-muted px-2 mb-lg-0">
              We have a full range of cars Across the most popular brands. SGI
              Certified used cars We inspect before we sell
            </p>
          </div>
          <div className="col-lg-3 col-md-6 mb-lg-0 mb-5">
            <i className="fas fa-briefcase pink-text fa-3x mb-4 animated pulse infinite"></i>
            <h4 className="mb-4">Car Service</h4>
            <p className="text-muted px-2 mb-lg-0">
              We are your professional partner for maintenance and repair for
              all vehicles
            </p>
          </div>
          <div className="col-lg-3 col-md-6 mb-md-0 mb-5">
            <i className="fas fa-certificate fa-3x amber-text mb-4 animated pulse infinite"></i>
            <h4 className="mb-4">SGI Inspection</h4>
            <p className="text-muted px-2 mb-md-0">
              Vehicle inspection programs make sure vehicles meet the safety
              standards required by law.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 mb-md-0 mb-5">
            <i className="fas fa-street-view fa-3x green-text mb-4 animated pulse infinite"></i>
            <h4 className="mb-4">Best User Care</h4>
            <p className="text-muted px-2 mb-md-0">
              Our customers are our first priority. Our certified Mechanics
              provides you with the best of their knowledge
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
