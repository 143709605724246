import React from "react";
import Dashboard from "./Dashboard";
import Login from "./Login";
export default class Admin extends React.Component {
  render() {
    let { allData, information, apiCallLoading, user } = this.props.data;
    if (user) {
      return (
        <Dashboard
          setActiveTab={this.props.setActiveTab}
          activeTab={this.props.activeTab}
          apiCallLoading={apiCallLoading}
          addCar={this.props.addNew}
          addSubscriber={(data) => this.props.addNew(data, "subscriber")}
          allData={allData}
          information={information}
          deleteSubscriber={(id) => this.props.delete(id, "subscriber")}
          deleteCar={this.props.delete}
          onUpdateCar={this.props.onUpdate}
          updateSubscriber={(data, id) =>
            this.props.onUpdate(data, id, "subscriber")
          }
          getSubscribers={this.props.getSubscribers}
          onUpdateInfo={(updatedObject, id, type = "info") =>
            this.props.onUpdate(updatedObject, id, type)
          }
        />
      );
    } else {
      return <Login login={this.props.login} />;
    }
  }
}
